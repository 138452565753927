import { tiendasApi } from "../api";

export const sucesosApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getSucesos: builder.mutation({
      query: ({ page, cantRows, incidencia, estado, tipoCortesDeCalles, horaDesde, horaHasta, fechaDesde, fechaHasta, input, empresas_asignadas }) => {
        const queryParams = new URLSearchParams({
          page,
          cantRows,
          tipoCortesDeCalles,
          fechaDesde,
          fechaHasta,
          horaDesde,
          horaHasta,
          estado,
          incidencia,
          empresas_asignadas,
          input,
        }).toString();
        return {
          url: `/suceso?${queryParams}`,
          method: "GET",
        };
      },
    }),
    getSuceso: builder.mutation({
      query: ({ id, isFicha = false, isComuna = false, isEmpresa = false }) => ({
        url: `/suceso/${id}?isFicha=${isFicha}&isComuna=${isComuna}&isEmpresa=${isEmpresa}`,
        method: "GET",
      }),
    }),
    getSucesosSinAsignar: builder.query({
      query: (filtros) => ({
        url: `/suceso/sucesos-sin-asignar`,
        params: filtros,
      }),
    }),
    getEstadoDuplicado: builder.query({
      query: (filtros) => ({
        url: `/suceso/estados-duplicados`,
        params: filtros,
      }),
    }),
    getEstadoDuplicadoEmpresa: builder.query({
      query: (filtros) => ({
        url: `/suceso/estados-duplicados-empresa`,
        params: filtros,
      }),
    }),
    getSucesosCerradosPorEmpresaSinFinalizarComuna: builder.query({
      query: (filtros) => ({
        url: `/suceso/sucesos-cerrados-estado-sin-finalizar`,
        params: filtros,
      }),
    }),
    getSucesosPorIncidencias: builder.query({
      query: (filtros) => ({
        url: `/suceso/sucesos-por-incidencias`,
        params: filtros,
      }),
    }),
    createSuceso: builder.mutation({
      query: (body) => ({
        url: "/suceso",
        method: "POST",
        body: body,
      }),
    }),
    updateSuceso: builder.mutation({
      query: ({ id, data }) => ({
        url: `/suceso/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteSuceso: builder.mutation({
      query: (id) => ({
        url: `/suceso/${id}`,
        method: "DELETE",
      }),
    }),
    downloadTsv: builder.mutation({
      query: ({ incidencia, estado, tipoCortesDeCalles, horaDesde, horaHasta, fechaDesde, fechaHasta, input }) => {
        const queryParams = new URLSearchParams({
          tipoCortesDeCalles,
          fechaDesde,
          fechaHasta,
          horaDesde,
          horaHasta,
          estado,
          incidencia,
          input,
        }).toString();
        return {
          url: `/suceso/descargas-tsv-comunas?${queryParams}`,
          method: "GET",
        };
      },
    }),
    uploadImage: builder.mutation({
      query: (body) => ({
        url: "/suceso",
        method: "POST",
        body: body,
      }),
    }),
    deleteImage: builder.mutation({
      query: (body) => ({
        url: "/suceso",
        method: "DELETE",
        body: body,
      }),
    }),
    uploadPartial: builder.mutation({
      query: ({ valor, campo, id }) => {
        return {
          url: `/suceso/updatePartial/${id}`,
          method: "PUT",
          body: { valor, campo },
        };
      },
    }),
  }),
});

export const {
  useGetSucesosMutation,
  useGetSucesoMutation,
  useDeleteImageMutation,
  useUploadPartialMutation,
  useCreateSucesoMutation,
  useUpdateSucesoMutation,
  useUploadImageMutation,
  useDeleteSucesoMutation,
  useDownloadTsvMutation,
  useGetEstadoDuplicadoQuery,
  useGetEstadoDuplicadoEmpresaQuery,
  useGetSucesosSinAsignarQuery,
  useGetSucesosCerradosPorEmpresaSinFinalizarComunaQuery,
  useGetSucesosPorIncidenciasQuery,
} = sucesosApiSlice;
