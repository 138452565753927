import { createSlice } from "@reduxjs/toolkit";

const initAlone = { email: "", nombre: "", telefono: "", contrasena: "", empresas_comunas: [] };
const paginationInfo = {
  hasNextPage: null,
  hasPrevPage: null,
  limit: 50,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: null,
  totalDocs: 0,
  totalPages: 0,
};
export const empresaSlice = createSlice({
  name: "empresa",
  initialState: {
    list: [],
    alone: initAlone,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    clean: (state, action) => {
      state.alone = initAlone;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
  },
});

export const { alone, setList, clean, setPagination } = empresaSlice.actions;

export const getListEmpresas = (state) => state.empresa.list;
export const getEmpresa = (state) => state.empresa.alone;
export const getPagination = (state) => state.empresa.pagination;
