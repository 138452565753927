import { createSlice } from "@reduxjs/toolkit";
import { dateRangeMap } from "../../../utils/formatDate";

const initAlone = {
  agencia: "",
  domicilio: "",
  tipo: "",
  subtipo: "",
  telefono_vecino: "",
  comuna: "",
  relato: "",
  origen: "",
  estado: "",
  geolocalizacion_lat: "",
  fecha: "",
  hora: "",
  fechahora: "",
  carga_administrador: "",
  identificador: "",
  datos_empresa: {
    incidencia: "",
    estado_empresa: "",
    observaciones: "",
    fotos_empresa: "",
    fecha_ult_modif: "",
  },
  datos_comuna: {
    domicilio_real: "",
    especie_arbol: { label: "", key: "" },
    dap: "",
    altura: "",
    incidencia: "",
    calle: { label: "", key: "" },
    corte_calle: "",
    observaciones: "",
    fotos_comuna: [],
    tipoDanio: "",
    fecha_ult_modif: "",
  },
};

const paginationInfo = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 50,
  nextPage: 2,
  page: 1,
  pagingCounter: 1,
  prevPage: false,
  totalDocs: 218,
  totalPages: 5,
};
const DEFAULT_INTERVALO = "last60Days";
const nuevasFechas = dateRangeMap[DEFAULT_INTERVALO]();

const filterSuceso = {
  input: "",
  fechaDesde: nuevasFechas.fechaDesde,
  fechaHasta: nuevasFechas.fechaHasta,
  horaDesde: nuevasFechas.horaDesde,
  horaHasta: nuevasFechas.horaHasta,
  intervalo_fechas: DEFAULT_INTERVALO,
  tipoCortesDeCalles: [],
  incidencia: [],
  estado: [],
  empresas_asignadas: [],
};

export const sucesosSlice = createSlice({
  name: "suceso",
  initialState: {
    list: [],
    alone: initAlone,
    filters: filterSuceso,
    filtersCleared: false,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    aloneComuna: (state, action) => {
      state.alone = {
        ...state.alone,
        datos_comuna: {
          ...state.alone.datos_comuna,
          ...action.payload,
        },
      };
    },
    aloneEmpresa: (state, action) => {
      state.alone = {
        ...state.alone,
        datos_empresa: {
          ...state.alone.datos_empresa,
          ...action.payload,
        },
      };
    },
    updateImagesComuna: (state, action) => {
      state.alone.datos_comuna.fotos_comuna = action.payload;
    },
    updateImagesEmpresas: (state, action) => {
      state.alone.datos_empresa.fotos_empresa = action.payload;
    },
    updateDatosComuna: (state, action) => {
      state.alone.datos_comuna = {
        ...state.alone.datos_comuna,
        ...action.payload,
      };
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setfilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clean: (state) => {
      state.alone = initAlone;
      state.filters = filterSuceso;
      /* state.pagination = paginationInfo; */
    },
    cleanFilters: (state) => {
      state.filtersCleared = true;
    },
    resetFiltersCleared: (state) => {
      state.filtersCleared = false;
    },
  },
});

export const { alone, list, clean, setfilters, updateImagesEmpresas, aloneEmpresa, resetFiltersCleared, setPagination, aloneComuna, cleanFilters, updateDatosComuna, updateImagesComuna } = sucesosSlice.actions;

export const getListSuceso = (state) => state.suceso.list;
export const getSuceso = (state) => state.suceso.alone;
export const getSucesoComuna = (state) => state.suceso.alone.datos_comuna;
export const getSucesoEmpresa = (state) => state.suceso.alone.datos_empresa;
export const getPagination = (state) => state.suceso.pagination;
export const getFilters = (state) => state.suceso?.filters || {};
export const getFiltersCleared = (state) => state.suceso.filtersCleared;
