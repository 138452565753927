import { tiendasApi } from "../api";

export const TipoEspecieArbolApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTiposEspecieArbol: builder.mutation({
      query: ({ page, name = "", limit }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
          name,
        }).toString();
        return {
          url: `/tipoespeciearbol?${queryParams}`,
          method: "GET",
        };
      },
    }),
    getTipoEspecieArbol: builder.mutation({
      query: (id) => ({
        url: "/tipoespeciearbol/" + id,
        method: "GET",
      }),
    }),
    getTipoEspecieCodigo: builder.mutation({
      query: (codigo) => {
        return {
          url: `/tipoespeciearbol/codigo/${codigo}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetTipoEspecieArbolMutation, useGetTiposEspecieArbolMutation, useGetTipoEspecieCodigoMutation } = TipoEspecieArbolApiSlice;
