import { memo, useCallback, useState } from "react";
import ArticleIcon from "@mui/icons-material/Article";
import { Divider, Grid } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getListEmpresas } from "../../../store/slices/empresa";
import { getListEstados } from "../../../store/slices/estado";
import { getFilters, setfilters } from "../../../store/slices/sucesos";
import { getListTipoCortes } from "../../../store/slices/tipoCortes";
import { getListTipoIncidente } from "../../../store/slices/tipoIncidentes";
import { dateRangeMap, listDatesWithOutCustomRange } from "../../../utils/formatDate";
import { ButtonIcon, Input } from "../../atoms";
import Select from "../../elementos/Select";
import { DatePickerCustom, SelectMultiple, TimePickerCustom } from "../../molecule";
import KeepMountedModal from "../../molecule/Modal/Modal";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const Filter = ({ setModalFilter, handleSave }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters, shallowEqual);
  const incidencia = useSelector(getListTipoIncidente, shallowEqual);
  const estados = useSelector(getListEstados, shallowEqual);
  const cortes = useSelector(getListTipoCortes, shallowEqual);
  const empresas = useSelector(getListEmpresas, shallowEqual);

  const [error, seterror] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);

  const changeValue = useCallback((value, name) => {
    if (name === "fechas") {
      const nuevasFechas = dateRangeMap[value]();
      setLocalFilters((prev) => ({ ...prev, ...nuevasFechas }));
    } else {
      setLocalFilters((prev) => ({ ...prev, [name]: value }));
    }
  }, []);

  const handleSubmit = async () => {
    dispatch(setfilters(localFilters));
    setModalFilter(false);
    handleSave(localFilters);
  };

  return (
    <KeepMountedModal setModalFilter={setModalFilter}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <h2 style={{ fontSize: "1.2rem", fontWeight: 300, textAlign: "center", margin: "2px 0px" }}>Filtros</h2>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Buscar por ID / Dirección / Teléfono vecino"
            icon={<ArticleIcon />}
            value={localFilters?.input}
            name="input"
            onChange={changeValue}
            type="text"
            restricciones={["alfanumerico"]}
            required={true}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select label="Rango fechas" options={listDatesWithOutCustomRange} onChange={changeValue} name={"fechas"} value={localFilters?.intervalo_fechas} />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerCustom value={localFilters?.fechaDesde} error={error} handleChange={changeValue} name={"fechaDesde"} label="Fecha Llamado Desde" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TimePickerCustom value={localFilters?.horaDesde} error={error} handleChange={changeValue} name={"horaDesde"} label="Hora Llamado Desde" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerCustom value={localFilters?.fechaHasta} error={error} handleChange={changeValue} name={"fechaHasta"} label="Fecha Llamado Hasta" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TimePickerCustom value={localFilters?.horaHasta} error={error} handleChange={changeValue} name={"horaHasta"} label="Hora Llamado Hasta" disabled={false} seterror={seterror} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Estado" disabled={false} defaultValue="" options={estados} type="text" onChange={changeValue} elegidos={localFilters?.estado ?? []} name={"estado"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Empresas asignadas" disabled={false} defaultValue="" options={empresas} type="text" onChange={changeValue} elegidos={localFilters?.empresas_asignadas} name={"empresas_asignadas"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Incidencia" disabled={false} defaultValue="" options={incidencia} type="text" onChange={changeValue} elegidos={localFilters?.incidencia} name={"incidencia"} />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectMultiple label="Corte de Calle" disabled={false} defaultValue="" options={cortes} type="text" onChange={changeValue} elegidos={localFilters?.tipoCortesDeCalles} name={"tipoCortesDeCalles"} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonIcon startIcon={<CheckOutlinedIcon />} variant="contained" color="success" noLink handleClick={handleSubmit} styleButton={{ marginLeft: "10px", color: "white" }}>
            Aplicar
          </ButtonIcon>
        </Grid>
      </Grid>
    </KeepMountedModal>
  );
};

export default memo(Filter);
