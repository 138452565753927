import React from "react";

import "../../assets/css/Input.css";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { createStyles } from "@mui/material";

export default function Select(props) {
  const handleSelectChange = (e) => {
    props.onChange(e.target.value, props.name);
  };

  return (
    <TextField
      select
      inputProps={{
        classes: {
          notchedOutline: styles.notchedOutline,
          backgroundColor: props.disabled && "#eaeaea",
        },
      }}
      label={props.label}
      key={props.label}
      name={props.label}
      id={props.label}
      value={props.value ?? ""}
      disabled={props.disabled}
      onChange={handleSelectChange}
      helperText={props.helperText}
      fullWidth
      style={{
        marginTop: "15px",
        height: "100%",
        maxHeight: "200px",
        display: "flex",
        alignItems: "flex-end",
      }}
      required={props.required}
      type={props.type}
      defaultValue={props.defaultValue}
      InputLabelProps={{ style: { textDecoration: props.decoration ? "line-through" : "" } }}
      InputProps={{
        /* startAdornment: (
          <InputAdornment position="end">
            <img src={props.icon} height={20} width={20} alt="option icon" />
          </InputAdornment>
        ), */
        startAdornment: <InputAdornment position="start">{props.icon}</InputAdornment>,
      }}
    >
      {props.options &&
        props.options.map((option) => (
          <MenuItem key={!isNaN(option.value) ? parseInt(option.value) : option.value} value={option.value}>
            {/*  <img src={props.icon} height={20} width={20} alt="option icon" style={{ paddingRight: "10px" }} /> */}
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
}

const styles = createStyles({
  notchedOutline: { borderColor: "red" },
});
