export const PUBLIC_ROUTES = {
  Login: "/",
  NotFound: "*",
};

export const PRIVATE_ROUTES = {
  FICHA_ADMIN: "/admin/ficha",
  //Users
  LIST_USERS: "/user",
  NEW_USERS: "/user/nuevo",
  EDIT_USER: "/user/editar",
  FICHA_USER: "/user/ficha",
  //Sucesos
  LIST_SUCESOS: "/sucesos",
  NEW_SUCESOS: "/sucesos/nuevo",
  EDIT_SUCESOS: "/sucesos/editar",
  FICHA_SUCESOS: "/sucesos/ficha",
  //Home
  HOME: "/home",
  //Mi Perfil
  SELF: "/mi-perfil",
};
