import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import BarChartIcon from "@mui/icons-material/BarChart";
import { PRIVATE_ROUTES } from "../routes/name-path.routes";

export const SidebarData = [
  {
    id: 1,
    label: "Indicadores",
    icono: (
      <BarChartIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.HOME,
    desplegable: false,
  },
  {
    id: 2,
    label: "Sucesos",
    icono: (
      <NaturePeopleIcon
        sx={{
          color: "black",
        }}
      />
    ),
    path: PRIVATE_ROUTES.LIST_SUCESOS,
    desplegable: false,
  },
];
