import { tiendasApi } from "../api";

export const TipoNombreCalleApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTipoNombreCalles: builder.mutation({
      query: ({ page, name = "", limit }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
          name,
        }).toString();
        return {
          url: `/tiponombrecalles?${queryParams}`,
          method: "GET",
        };
      },
    }),
    getTipoNombreCalle: builder.mutation({
      query: (id) => ({
        url: "/tiponombrecalles/" + id,
        method: "GET",
      }),
    }),
    getTipoNombreCalleCodigo: builder.mutation({
      query: ({ codigo }) => {
        return {
          url: `/tiponombrecalles/codigo/${codigo}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetTipoNombreCallesMutation, useGetTipoNombreCalleMutation, useGetTipoNombreCalleCodigoMutation } = TipoNombreCalleApiSlice;
