import { lazy } from "react";
import { PRIVATE_ROUTES } from "./name-path.routes";
const HomeScreen = lazy(() => import("../pages/HomeScreen"));
const MiPerfilScreen = lazy(() => import("../pages/MiPerfilScreen"));
const SucesoEditScreen = lazy(() => import("../pages/SucesoEditScreen"));
const SucesoFichaScreen = lazy(() => import("../pages/SucesoFichaScreen"));
const SucesoNewScreen = lazy(() => import("../pages/SucesoNewScreen"));
const SucesosScreen = lazy(() => import("../pages/SucesoScreen"));

export const ROUTES_PRIVATES = [
  //Sucesos
  { path: PRIVATE_ROUTES.LIST_SUCESOS, component: SucesosScreen },
  { path: PRIVATE_ROUTES.NEW_SUCESOS, component: SucesoNewScreen },
  { path: PRIVATE_ROUTES.FICHA_SUCESOS, component: SucesoFichaScreen },
  { path: PRIVATE_ROUTES.EDIT_SUCESOS, component: SucesoEditScreen },
  //Home
  { path: PRIVATE_ROUTES.HOME, component: HomeScreen },

  //Mi perfil
  { path: PRIVATE_ROUTES.SELF, component: MiPerfilScreen },
];
