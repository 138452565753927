import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";

const KeepMountedModal = ({ children, setModalFilter, modalState = true, modalSize }) => {
  const handleClose = () => {
    setModalFilter(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: modalSize ? modalSize : "60%",
    maxWidth: "900px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  React.useEffect(() => {
    setModalFilter(modalState);
  }, []);

  return (
    <>
      <Modal keepMounted open={modalState} onClose={handleClose} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
        <Box sx={style}>{children}</Box>
      </Modal>
    </>
  );
};

export default KeepMountedModal;
