import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonIcon } from "../atoms";
import { PRIVATE_ROUTES } from "../../routes/name-path.routes";
import { convertDateUtc } from "../../utils/formatDate";

export const TableRowSuceso = ({ row, colores }) => (
  <tr style={{ height: "10%" }}>
    <td>
      <p>{convertDateUtc(row?.carga_fechahora)}</p>
    </td>
    <td style={{ textAlign: "left" }}>
      <p>{row?.domicilio}</p>
    </td>
    <td>
      <p>{row?.subtipo}</p>
    </td>
    <td align="">
      <p>{row?.comuna}</p>
    </td>
    <td align="">
      <p>{row?.datos_comuna?.tipoDanio ?? "-"}</p>
    </td>
    <td align="">
      <p>{row?.datos_comuna?.tipoIncidencia ?? "-"}</p>
    </td>
    <td align="" style={{ backgroundColor: colores[row?.estado] }}>
      <p>{row?.estado ?? "-"}</p>
    </td>
    <td align="">
      <p>{row?.datos_comuna?.corte_de_calle ?? "-"}</p>
    </td>
    <td align="">
      <p>{row?.datos_empresa?.estado_empresa ?? "-"}</p>
    </td>
    <td align="">
      <ButtonIcon arialLabel="detailsUser" startIcon={<SearchIcon />} to={`${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${row._id}`} />
    </td>
  </tr>
);
